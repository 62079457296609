import * as React from "react"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "./index.css"

const IndexPage = () => {
    return (
        <Layout>
            <Seo title="Адвокатский кабинет Храпова Юрия Николаевича"/>
            <h2 className="main-title">
                Адвокатский кабинет
            </h2>
            <div>
                <div className={"row"}>
                    <div className={"col-1 text-end"}>
                        От
                    </div>
                    <div className={"col-auto text-red text-uppercase"}>
                        бесплатной консультации
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-1 text-end"}>
                        к
                    </div>
                    <div className={"col-auto text-red text-uppercase"}>
                        разрешению вопроса,
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-1"}/>
                    <div className={"col-auto text-red text-uppercase"}>
                        выигранному спору,
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-1"}/>
                    <div className={"col-auto text-red text-uppercase"}>
                        выгодному исходу
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <StaticImage
                    src="../images/ks.jpg"
                    alt="Конституционный суд"
                    placeholder="none"
                    layout="fixed"
                    className={"m-4"}
                />
            </div>
            <div>
                <strong>Мы можем быть осторожными</strong> и просчитывать для Вас каждый шаг для достижения
                цели. Медленно двигаясь вперед вплоть до Конституционного суда.
            </div>
            <div className="d-flex justify-content-center">
                <StaticImage
                    src="../images/speed.jpg"
                    alt="Быстрая помощь"
                    placeholder="none"
                    layout="fixed"
                    className={"m-4"}
                />
            </div>
            <div className="d-flex" >
                Или <span style={{color: 'red'}}>&nbsp;ГОТОВЫ РАБОТАТЬ НА ГРАНИ ФОЛА &nbsp;</span> отстаивая Ваши интересы и спеша Вам на помощь
            </div>
            <div>
                <div className={"row"}>
                    <div className={"col-auto"}>
                        &nbsp;
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default IndexPage
